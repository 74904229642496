import './src/assets/styles/global.css'
import './src/assets/styles/modal.css'

import React from 'react'
import { RecoilRoot } from 'recoil'

export const wrapRootElement = ({ element }) => {
    return (
        <RecoilRoot>
            {element}
        </RecoilRoot>
    )
}